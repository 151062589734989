<template>
  <a href="#" class="logout" data-remote="true" @click.prevent="showLoginSSO">
    <div v-if="(notLoggedTitle != '' || notLoggedDesc != '') && isMobile" class="login-message">
      <span class="title">{{ notLoggedTitle }}</span>
      <span class="desc">{{ notLoggedDesc }}</span>
    </div>
    <!-- .message -->
    <div class="info-login-content utag-ib-register">
      <div class="info-login-content__left">
        <div class="icon"></div>
        <span class="name">{{ title }}</span>
      </div>
      <div class="info-login-content__right">
        <button class="btn-primary">{{ loginText }}</button>
      </div>
    </div> </a><!-- .logout -->
</template>
<script>
import { auth0Login } from '../../../../auth0/sso'
import { currentDevice } from '../../../../core/utils/currentDevice'
import loaderSpinner from '../../../../shared/loader_spinner/loader_spinner'

const componentName = 'NotLogged'
export default {
  name: componentName,
  props: {
    notLoggedTitle: String,
    notLoggedDesc: String,
    title: String,
    loginText: String,
  },
  computed: {
    isMobile() {
      return currentDevice.isMobile
    },
  },
  methods: {
    showLoginSSO() {
      loaderSpinner.show()
      auth0Login(false)
    },
  },
}
</script>
