<template>
  <div class="menu-panel" :class="{ opened: isOpened }">
    <div class="has-children" @click="openPanel">
      <slot name="parent"></slot>
    </div>
    <!-- .has-children -->
    <div class="is-children">
      <div class="back" @click.stop="closePanel">
        <span class="text">{{ backText }}</span>
        <span v-if="!headerImg.src" class="panel-title t-label">{{ getPanelTitle() }}</span>
        <img v-else :src="headerImg.src" :alt="headerImg.alt" />
      </div>
      <slot name="children"></slot>
    </div>
    <!-- .is-children -->
  </div>
  <!-- .menu-panel -->
</template>

<script>
import { sendCatAndOptionalEvents } from '../../../../core/analytics/utag_events'

const componentName = 'MobileMenuPanel'

export default {
  name: componentName,
  inject: ['backText'],
  props: {
    config: Object,
    panelTitle: String,
    headerImg: {
      type: Object,
      default: () => {
        return {
          src: '',
          alt: '',
        }
      },
    },
    utagEventInfo: Object,
  },
  data() {
    return {
      isOpened: false,
    }
  },
  methods: {
    getPanelTitle() {
      return this.panelTitle.replace(':', '')
    },
    openPanel() {
      const parentOpened = this.$el.parentNode.closest('.menu-panel.opened')

      if (parentOpened !== null) {
        parentOpened.querySelector('.is-children').scrollTo(0, 0)
        parentOpened.classList.add('hide-overflow')
      }

      this.isOpened = true

      if (this.$el.parentNode.closest('.top-elements')) {
        this.sendUtagEvent(this.eventInfo)
      }
    },
    closePanel() {
      const parentClosed = this.$el.parentNode.closest('.menu-panel.hide-overflow')

      if (parentClosed !== null) {
        parentClosed.classList.remove('hide-overflow')
      }

      this.isOpened = false
    },
    sendUtagEvent(eventInfo) {
      sendCatAndOptionalEvents('main-menu menu', eventInfo ? eventInfo : this.utagEventInfo)
    },
  },
}
</script>
