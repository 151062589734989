<template>
  <div class="menu-user" @click="redirectToMyProfile">
    <img v-if="user.image_url && user.image_url != ''" :src="user.image_url" alt="Avatar" class="image" />
    <span v-else-if="user.name && user.name != ''" class="letter">{{ user.name.charAt(0) }}</span>
    <div class="info-login-content">
      <span class="title">{{ panelTitle }} - </span>
      <span v-if="user.name && user.name != ''" class="name t-link-secondary">
        {{ user.name }}
      </span>
      <span v-else class="name t-link-secondary">{{ panelTitle }}</span>
    </div>
  </div>
</template>
<script>
const componentName = 'Logged'
export default {
  name: componentName,
  props: {
    user: Object,
    panelTitle: String,
    urlMyProfile: {
      type: String,
      required: false,
      default: '',
    },
  },
  methods: {
    redirectToMyProfile() {
      if (this.urlMyProfile !== '') {
        window.location.href = this.urlMyProfile
      }
    },
  },
}
</script>
