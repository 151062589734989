// Disable jquery lints for legibility TO_DO delet jquery lints
/* eslint-disable no-jquery/no-attr */
/* eslint-disable no-jquery/no-data */
/* eslint-disable no-jquery/no-text */
/* eslint-disable no-jquery/no-visibility */
/* eslint-disable no-jquery/no-other-methods */
/* eslint-disable no-jquery/no-html */
/* eslint-disable no-jquery/no-class */
/* eslint-disable no-jquery/no-other-utils */
/* eslint-disable no-jquery/no-jquery-constructor */
/* eslint-disable no-jquery/no-find-collection */
/* eslint-disable no-jquery/no-each-collection */
import { hidePartnerLogo, isPartner, showPartnerLogo } from '../../../../core/iberapi/partner_shower/partner_shower'
import { currentDevice } from '../../../../core/utils/currentDevice'
import { setCookie } from '../../../../utils/cookie_manager'
import getRealMarketContent, { dispatchRealMarketContentUpdatedEvent } from '../../../../utils/get_real_market_content'

const Menu = {
  data() {
    return {
      storageData: {},
    }
  },
  methods: {
    initTemplates() {
      this.initLastSearches()
      IB.browsing_sessions.readRedis(() => {
        this.storageData = IB.browsing_sessions.getData()?.redis_values
        this.initUserTemplate()
      })

      this.initBookingsTemplate()
      this.initExperiencesTemplate()
      this.initOffersTemplate()
      this.initBannersTemplate()
      if (isPartner()) {
        showPartnerLogo()
      } else {
        hidePartnerLogo()
      }
    },

    /**
     * Inicializar información sobre las últimas búsquedas
     */
    initLastSearches() {
      const local_last_searches = localStorage.getItem('last_searches')
      if (local_last_searches !== null) $(this.lastSearchesSelector).removeClass('hidden')
    },

    /**
     * Inicializamos el contenido del panel del área privada del usuario
     */
    initUserTemplate() {
      const info_user_level_template = $('#info-user-level-template').html()
      $(`${this.baseSelector} .info-user-container`).append(info_user_level_template)

      //Logout - Lo metemos en esta función para que vue sea capaz de leerlo si cerramos la sesión desde "Mi perfil"
      document.querySelectorAll('.logout-link').forEach(link => {
        link.addEventListener('click', function () {
          setCookie('prelogout_url', window.location.href)
        })
      })

      //Cambiamos los valores por los del usuario
      if (this.storageData) {
        const userInfoInBar = document.querySelector('.user-info-in-navbar p.title')
        try {
          //Cambiamos el nombre
          if (typeof this.storageData.name === 'undefined') {
            userInfoInBar.textContent = userInfoInBar.textContent.replace('{name}', '').replace(', ', '')
          } else {
            userInfoInBar.textContent = userInfoInBar.textContent.replace('{name}', this.storageData.name)
          }
          //Cambiar imagen de perfil
          if (typeof IB.browsing_sessions !== 'undefined') {
            IB.browsing_sessions.setUserImage(this.storageData.image_url, this.storageData.name)
          }
        } catch (err) {
          console.log(`Error loading Horizons Menu: ${err}`)
        }
      }

      // Gestión del alert de IHG
      const closeBtn = document.querySelector('.js-message-alert-close-btn')
      const messageAlert = document.querySelector('#ihg-message-alert')

      if (localStorage.getItem('ihg-message-alert-state') === 'closed') {
        messageAlert?.classList.add('hidden')
      }

      closeBtn?.addEventListener('click', function () {
        messageAlert.classList.add('hidden')
        localStorage.setItem('ihg-message-alert-state', 'closed')
      })
    },

    /**
     * Inicializamos el contenido del panel de reservas del menú
     */
    initBookingsTemplate() {
      const my_bookings_form_template = $('#my-bookings-form-template').html()
      $(`${this.baseSelector} .my-bookings--container`).append(my_bookings_form_template)
      if (this.storageData !== null && $('.js-user-options-dropdown-link[data-menu-name="my-iberostar"]').length) {
        // If user is logged
        if ($(`${this.baseSelector}  .my-bookings--container .user-horizons-info p`).length) {
          $(`${this.baseSelector}  .my-bookings--container .user-horizons-info p`).html(
            $(`${this.baseSelector} .my-bookings--container .user-horizons-info p`)
              .html()
              .replace('{name}', this.storageData.name)
          )
        }
      } else {
        $(`${this.baseSelector}  .my-bookings--container .user-horizons-info`).hide()
      }

      if (!this.initializedBookingsForm) {
        IB.forms.reservation_management.init($(`${this.baseSelector} .js-reservation-management-form`))
        this.initializedBookingsForm = true
      }
    },

    /**
     * Inicializamos el contenido del panel de experiencias, sección destinos
     */
    initExperiencesTemplate() {
      const menu_experiences_template = $('#menu-experiences-template').html()
      $(`${this.baseSelector} .experiences--list`).append(menu_experiences_template)
    },

    /**
     * Inicializamos el contenido del panel de ofertas, sección ofertas del menú
     */
    initOffersTemplate() {
      const offerItemsContainerSelector = `${this.baseSelector} .offer-items-container`
      const offerItemsContainer = document.querySelector(offerItemsContainerSelector)

      if (!offerItemsContainer) return

      const $offerItemsContainer = $(offerItemsContainerSelector)

      const appendAndInitializeOffers = htmlContent => {
        offerItemsContainer.innerHTML = htmlContent
        IB.fastbooking.discount_resident.updatePricesInfo()
        IB.currencyForm.forceUpdate($offerItemsContainer)
      }

      const menuOffersTemplate = $('#menu-offers-template').html()

      appendAndInitializeOffers(menuOffersTemplate)

      // Avoid make desktop header request if current device is mobile
      const isDesktopHeader = offerItemsContainer.closest('.js-main-header')
      if (currentDevice.isMobile && isDesktopHeader) return

      // Replace offers html with the real market html content and reinitialize the block
      getRealMarketContent({ contentName: 'menu_offers' })
        .then(response => {
          appendAndInitializeOffers(response.html_replacement)
          IB.lazyImg.loadAllImagesSelector($offerItemsContainer)
          dispatchRealMarketContentUpdatedEvent({
            blockSelector: offerItemsContainerSelector,
            blockName: 'menu-offers',
          })
        })
        .catch(error => {
          // console.warn(error);
        })
    },

    /**
     * Inicializamos el contenido del panel de ofertas, sección banners del menú
     */
    initBannersTemplate() {
      const bannerContainerSelector = `${this.baseSelector} .banners-container`
      const bannerContainer = document.querySelector(bannerContainerSelector)

      if (!bannerContainer) return

      const menuBannersTemplate = $('#menu-banners-template').html()
      bannerContainer.innerHTML = menuBannersTemplate

      // Avoid make desktop header request if current device is mobile
      const isDesktopHeader = bannerContainer.closest('.js-main-header')
      if (currentDevice.isMobile && isDesktopHeader) return

      // Replace offers html with the real market html content and reinitialize the block
      getRealMarketContent({ contentName: 'menu_banners' })
        .then(response => {
          bannerContainer.innerHTML = response.html_replacement
          IB.lazyImg.loadAllImagesSelector($(bannerContainerSelector))
          dispatchRealMarketContentUpdatedEvent({ blockSelector: bannerContainerSelector, blockName: 'menu-banners' })
        })
        .catch(error => {
          // console.warn(error);
        })
    },
  },
}

export default Menu
