import { sendUtagEvent } from '../../../../core/analytics/utag_events'

export function emitMobileMenuItemEvent(utagEventInfo) {
  if (utagEventInfo) {
    sendUtagEvent({
      data: {
        event_name: 'menu_main',
        event_cat: 'main-menu menu',
        event_act: utagEventInfo.event_act,
        event_purpose: 'information',
        event_structure: 'header',
        event_lbl: '',
      },
    })
  }
}
