<template>
  <li v-if="item.name || item.item?.name" :class="getClass('menu-item', type, dropdown, panel, tab)">
    <template v-if="!panel">
      <a
        :class="getClass('link', item.css_class)"
        :href="item.url"
        :rel="item.rel ? item.rel : false"
        :target="item.target ? item.target : false"
        :data-no-turbolink="item.turbolinks ? false : true"
        :data-visible-to-the-club-user="item.visible_to_the_club_user ? item.visible_to_the_club_user : false"
        :data-event-label="item.kind == 'page' ? item.event_name : false"
        :data-method="item.logout_method ? item.logout_method : false"
        @click="execAction"
      >
        {{ item.name ? item.name : item.item.name }}
      </a>
      <ul v-if="dropdown" class="dropdown-children">
        <slot name="dropdown"></slot>
      </ul>
    </template>
    <template v-else>
      <MobileMenuPanel ref="menuPanel" :panel-title="item.name" :utag-event-info="utagEventInfo">
        <template #parent>
          <a :class="getClass('link', item.css_class)" :href="item.url">
            {{ fullItemName }}
            <span class="number-of">{{ item.num }}</span>
          </a>
        </template>
        <template #children>
          <slot name="panel"></slot>
        </template>
      </MobileMenuPanel>
    </template>
  </li>
  <!-- .menu-item -->
</template>

<script>
import MenuItem from '../../mixins/MenuItem/MenuItem'
import MobileMenuPanel from '../MobileMenuPanel/MobileMenuPanel'

import { emitMobileMenuItemEvent } from './MobileMenuItem.analytics'

const componentName = 'MobileMenuItem'

export default {
  name: componentName,
  components: {
    MobileMenuPanel,
  },
  mixins: [MenuItem],
  props: {
    item: Object,
    itemModifier: String,
    utagEventInfo: Object,
    children: Array,
    type: String,
    dropdown: Boolean,
    panel: Boolean,
    tab: Boolean,
    panelTitle: String,
    utagEventInfo: Object,
  },
  data() {
    return {
      dropdownOpened: false,
    }
  },
  computed: {
    fullItemName() {
      return this.item.name + (this.itemModifier ? ` ${this.itemModifier}` : '')
    },
  },
  methods: {
    execAction(event) {
      if (this.item.action) {
        event.preventDefault()
        this[this.item.action]()
      } else if (this.dropdown) {
        event.preventDefault()
        this.toggleDropdown()
      } else if (this.tab) {
        event.preventDefault()
        const parentID = event.currentTarget.parentNode.id
        this.togglePanel(parentID)
        this.sendUtagEvent()
      } else if (this.utagEventInfo) {
        this.sendUtagEvent()
      } else if (this.item.css_class && this.item.css_class.includes('offers-menu-item')) {
        const utagInfo = {
          event_cat: 'menu-main menu',
          event_act: 'offers',
          event_lbl: '',
        }
        this.sendUtagEvent(utagInfo)
      } else if (this.item.url && this.item.url.includes('waveofchange')) {
        const utagInfo = {
          event_cat: 'menu-main menu',
          event_act: 'wave of change',
          event_lbl: '',
        }
        this.sendUtagEvent(utagInfo)
      }else if (this.item && this.item.utag_event_info){
        emitMobileMenuItemEvent(this.item.utag_event_info)
      }
      emitMobileMenuItemEvent(this.utagEventInfo)
    },

    toggleDropdown() {
      if (!this.dropdownOpened) {
        this.sendUtagEvent()
      }
      if (this.dropdown) {
        this.dropdownOpened = !this.dropdownOpened
      }
    },
    togglePanel(id) {
      // Activo la tab correspondiente al continente seleccionado
      for (var item of document.querySelectorAll('.continents-tab .tab')) {
        item.id.includes(id) ? item.classList.add('active') : item.classList.remove('active')
      }
      // Muestro el panel de países del continente seleccionado
      for (var item of document.querySelectorAll("[id^='continent-panel-']")) {
        item.id.includes(id) ? item.classList.remove('hidden') : item.classList.add('hidden')
      }
    },
    closePanel() {
      this.$refs.menuPanel.closePanel()
    },
  },
}
</script>
