<template>
  <div v-if="!isCuba" class="private-area utag-ib-register" data-utag-ib-register-type="header">
    <template v-if="!user.logged_in">
      <NotLogged
        :not-logged-title="config.notLoggedTitle"
        :not-logged-desc="config.notLoggedDesc"
        :title="panelTitle"
        :login-text="config.loginText"
      />
    </template>
    <template v-else>
      <div class="logged">
        <!-- Private Área Logged in Desktop (without children panel) -->
        <Logged v-if="!isMobile" :user="user" :panel-title="panelTitle" :url-my-profile="config.urlMyProfile" />
        <!-- Private Área Logged in Mobile (with children panel) -->
        <MobileMenuPanel v-else :panel-title="config.panelTitle">
          <template #parent>
            <Logged :user="user" :panel-title="panelTitle" />
          </template>
          <template #children>
            <div class="info-user-container">
              <!-- RENDERIZO POR JS COGIENDO EL CONTENIDO DE #info-user-level-template -->
            </div>
            <div class="user-options">
              <ul class="options-menu">
                <MobileMenuItem
                  v-for="item in config.userMenu"
                  :key="item.id"
                  :item="item"
                  :type="'child-item-with-arrow'"
                />
              </ul>
              <ul class="options-logout">
                <MobileMenuItem :item="config.logoutItem" :type="'child-item-with-arrow'" />
              </ul>
            </div>
            <!-- .menu-options -->
          </template>
        </MobileMenuPanel>
      </div>
    </template>
  </div>
</template>

<script>
import { currentDevice } from '../../../../core/utils/currentDevice'
import MobileMenuItem from '../MobileMenuItem/MobileMenuItem'
import MobileMenuPanel from '../MobileMenuPanel/MobileMenuPanel'

import Logged from './Logged'
import NotLogged from './NotLogged'

const componentName = 'PrivateArea'
export default {
  name: componentName,
  components: {
    MobileMenuPanel,
    MobileMenuItem,
    NotLogged,
    Logged,
  },
  props: {
    config: Object,
  },
  data() {
    return {
      user: { logged_in: false },
    }
  },
  computed: {
    isMobile() {
      return currentDevice.isMobile
    },
    isCuba() {
      return document.documentElement.dataset.inMicrosite === 'ibercuba'
    },
    panelTitle() {
      return this.config.panelTitle.replace(':', '')
    },
  },
  mounted() {
    IB.browsing_sessions.readRedis(this.init)
  },
  methods: {
    init() {
      if (IB.browsing_sessions !== null && IB.browsing_sessions.getData())
        this.user = IB.browsing_sessions.getData().redis_values
    },
  },
}
</script>
