import { sendCatAndOptionalEvents } from '../../../../core/analytics/utag_events'

const MenuItem = {
  methods: {
    getClass(class_name, property, dropdown = false, panel = false, tab = false) {
      const klass = [
        class_name,
        ...(property ? [property] : []),
        ...(dropdown ? ['dropdown'] : []),
        ...(tab ? ['tab'] : []),
        ...(dropdown && this.dropdownOpened ? ['dropdown--opened'] : []),
        ...(panel ? ['panel'] : []),
      ]
      return klass.join(' ')
    },

    sendUtagEvent(eventInfo) {
      sendCatAndOptionalEvents('main-menu menu', eventInfo ? eventInfo : this.utagEventInfo)
    },
  },
}

export default MenuItem
