<template>
  <li class="accessibility-button" @click="toggleAdaMode">
    <span class="s s-ico_a11y s-s"></span>
    <div class="accessibility-button-text">
      <span class="a11y-no" :class="{'hidden': isA11y}">{{ config.increase }}</span>
      <span class="a11y-yes" :class="{'hidden': !isA11y}">{{ config.decrease }}</span>
    </div>
  </li>
</template>

<script>
const componentName = 'AccessibilityButton';

export default {
  name: componentName,
  props: {
    config: Object
  },
  data() {
    return {
      isA11y: IB.a11y.isAdaActivated()
    }
  },
  methods: {
    toggleAdaMode() {
      // execute AFTER the click event on '.accessibility-button' defined in accessibility.js
      setTimeout(() => {
        IB.a11y.onAdaButtonClick()
        this.isA11y = IB.a11y.isAdaActivated()
      }, 0);
    }
  },
  mounted() {
    this.isA11y = IB.a11y.isAdaActivated() && !IB.is_in_microsite
  }
}
</script>
