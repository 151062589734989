import { currentDevice } from '../../../../core/utils/currentDevice'

export function loadStyles({ name, folder = 'components', test_name = '' }) {
  const stylesStandard = [name, test_name ? `_${test_name}` : ''].join('')

  const stylesMobile = [stylesStandard, currentDevice.isMobile ? 'Mobile' : ''].join('')

  // If we are in mobile, try to load de Mobile version
  // If not available, load the Standard version
  import(
    /* webpackChunkName: "[request]" */
    `../../${folder}/${name}/${stylesMobile}.scss`
  ).catch(() => {
    import(
      /* webpackChunkName: "[request]" */
      `../../${folder}/${name}/${stylesStandard}.scss`
    ).catch(err => {
      // console.warn(name, stylesStandard, err)
    })
  })
}

const LoadStyles = {
  created() {
    if (this.$options.name) {
      loadStyles({ name: this.$options.name, test_name: this.testName })
    }
  },
}

export default LoadStyles
